import reportsTypes from "./reports.types";
import reportTypes from "./reports.types";

const INITIAL_STATE = {
  reports: [],
  report: {},
};

const reportsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case reportTypes.SET_REPORTS:
      return {
        ...state,
        reports: action.payload,
      };
    case reportsTypes.SET_REPORT:
      return {
        ...state,
        report: action.payload,
      };
    default:
      return state;
  }
};

export default reportsReducer;
