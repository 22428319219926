import businessBookingsTypes from "./businessBookings.types";

export const addBusinessBookingStart = (businessBookingData, businessBookingID) => ({
  type: businessBookingsTypes.ADD_NEW_BUSINESS_BOOKING_START,
  payload: { ...businessBookingData, businessBookingID },
});

export const fetchBusinessBookingsStart = (filters = {}) => ({
  type: businessBookingsTypes.FETCH_BUSINESS_BOOKINGS_START,
  payload: filters,
});

export const setBusinessBookings = (businessBookings) => ({
  type: businessBookingsTypes.SET_BUSINESS_BOOKINGS,
  payload: businessBookings,
});

export const deleteBusinessBookingStart = (businessBookingID) => ({
  type: businessBookingsTypes.DELETE_BUSINESS_BOOKING_START,
  payload: businessBookingID,
});

export const fetchBusinessBookingStart = (businessBookingID) => ({
  type: businessBookingsTypes.FETCH_BUSINESS_BOOKING_START,
  payload: businessBookingID,
});

export const setBusinessBooking = (businessBooking) => ({
  type: businessBookingsTypes.SET_BUSINESS_BOOKING,
  payload: businessBooking,
});

export const updateBusinessBookingStart = (businessBookingData) => ({
  type: businessBookingsTypes.UPDATE_BUSINESS_BOOKING_START,
  payload: businessBookingData,
});

export const updateBusinessBookingSuccess = (businessBooking) => ({
  type: businessBookingsTypes.UPDATE_BUSINESS_BOOKING_SUCCESS,
  payload: businessBooking,
});
