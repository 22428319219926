import { firestore } from "../../firebase/utils";

export const handleAddBusinessBooking = (businessBooking, businessBookingID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("businessBookings")
      .doc(businessBookingID)
      .set(businessBooking)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleFetchBusinessBookings = ({
  filterType,
  startAfterDoc,
  persistBusinessBookings = [],
}) => {
  return new Promise((resolve, reject) => {
    const pageSize = 9;

    let ref = firestore
      .collection("businessBookings")
      .orderBy("createdDate", "desc")
      .limit(pageSize);

    if (filterType) ref = ref.where("businessBookingCategory", "==", filterType);
    if (startAfterDoc) ref = ref.startAfter(startAfterDoc);

    ref
      .get()
      .then((snapshot) => {
        const totalCount = snapshot.size;

        const data = [
          ...persistBusinessBookings,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              documentID: doc.id,
            };
          }),
        ];

        resolve({
          data,
          queryDoc: snapshot.docs[totalCount - 1],
          isLastPage: totalCount < 9,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleDeleteBusinessBooking = (documentID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("businessBookings")
      .doc(documentID)
      .delete()
      .then(() => {
        console.log(documentID, "Deleted");
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleFetchBusinessBooking = (businessBookingID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("businessBookings")
      .doc(businessBookingID)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          resolve({
            ...snapshot.data(),
            documentID: businessBookingID,
          });
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const handleUpdateBusinessBooking = (businessBooking) => {
  const { documentID, ...updatedBusinessBooking } = businessBooking;

  const nonEmptyFields = Object.fromEntries(
    Object.entries(updatedBusinessBooking).filter(([_, value]) => value !== "")
  );

  console.log(
    "Updating document with ID:",
    documentID,
    "with data:",
    nonEmptyFields
  );

  return new Promise((resolve, reject) => {
    firestore
      .collection("businessBookings")
      .doc(documentID)
      .update(nonEmptyFields)
      .then(() => {
        resolve({ ...businessBooking });
      })
      .catch((err) => {
        console.error("Update error:", err);
        reject(err);
      });
  });
};
