import businessFeedbacksTypes from "./businessFeedbacks.types";

export const addBusinessFeedbackStart = (businessFeedbackData) => ({
  type: businessFeedbacksTypes.ADD_NEW_BUSINESS_FEEDBACK_START,
  payload: businessFeedbackData,
});

export const fetchBusinessFeedbacksStart = (filters = {}) => ({
  type: businessFeedbacksTypes.FETCH_BUSINESS_FEEDBACKS_START,
  payload: filters,
});

export const setBusinessFeedbacks = (businessFeedbacks) => ({
  type: businessFeedbacksTypes.SET_BUSINESS_FEEDBACKS,
  payload: businessFeedbacks,
});

export const deleteBusinessFeedbackStart = (businessFeedbackID) => ({
  type: businessFeedbacksTypes.DELETE_BUSINESS_FEEDBACK_START,
  payload: businessFeedbackID,
});

export const fetchBusinessFeedbackStart = (businessFeedbackID) => ({
  type: businessFeedbacksTypes.FETCH_BUSINESS_FEEDBACK_START,
  payload: businessFeedbackID,
});

export const setBusinessFeedback = (businessFeedback) => ({
  type: businessFeedbacksTypes.SET_BUSINESS_FEEDBACK,
  payload: businessFeedback,
});
