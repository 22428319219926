import { firestore } from "../../firebase/utils";

export const handleAddBusinessOrder = (businessOrder, businessOrderID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("businessOrders")
      .doc(businessOrderID)
      .set(businessOrder)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleFetchBusinessOrders = ({
  filterType,
  startAfterDoc,
  persistBusinessOrders = [],
}) => {
  return new Promise((resolve, reject) => {
    const pageSize = 9;

    let ref = firestore
      .collection("businessOrders")
      .orderBy("createdDate", "desc")
      .limit(pageSize);

    if (filterType) ref = ref.where("businessOrderCategory", "==", filterType);
    if (startAfterDoc) ref = ref.startAfter(startAfterDoc);

    ref
      .get()
      .then((snapshot) => {
        const totalCount = snapshot.size;

        const data = [
          ...persistBusinessOrders,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              documentID: doc.id,
            };
          }),
        ];

        resolve({
          data,
          queryDoc: snapshot.docs[totalCount - 1],
          isLastPage: totalCount < 9,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleDeleteBusinessOrder = (documentID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("businessOrders")
      .doc(documentID)
      .delete()
      .then(() => {
        console.log(documentID, "Deleted");
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleFetchBusinessOrder = (businessOrderID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("businessOrders")
      .doc(businessOrderID)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          resolve({
            ...snapshot.data(),
            documentID: businessOrderID,
          });
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const handleUpdateBusinessOrder = (businessOrder) => {
  const { documentID, ...updatedBusinessOrder } = businessOrder;

  const nonEmptyFields = Object.fromEntries(
    Object.entries(updatedBusinessOrder).filter(([_, value]) => value !== "")
  );

  console.log(
    "Updating document with ID:",
    documentID,
    "with data:",
    nonEmptyFields
  );

  return new Promise((resolve, reject) => {
    firestore
      .collection("businessOrders")
      .doc(documentID)
      .update(nonEmptyFields)
      .then(() => {
        resolve({ ...businessOrder });
      })
      .catch((err) => {
        console.error("Update error:", err);
        reject(err);
      });
  });
};
