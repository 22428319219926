const businessBookingsTypes = {
  ADD_NEW_BUSINESS_BOOKING_START: "ADD_NEW_BUSINESS_BOOKING_START",
  FETCH_BUSINESS_BOOKINGS_START: "FETCH_BUSINESS_BOOKINGS_START",
  SET_BUSINESS_BOOKINGS: "SET_BUSINESS_BOOKINGS",
  DELETE_BUSINESS_BOOKING_START: "DELETE_BUSINESS_BOOKING_START",
  FETCH_BUSINESS_BOOKING_START: "FETCH_BUSINESS_BOOKING_START",
  SET_BUSINESS_BOOKING: "SET_BUSINESS_BOOKING",

  UPDATE_BUSINESS_BOOKING_START: "UPDATE_BUSINESS_BOOKING_START",
  UPDATE_BUSINESS_BOOKING_SUCCESS: "UPDATE_BUSINESS_BOOKING_SUCCESS",
};

export default businessBookingsTypes;
