import { takeLatest, put, all, call } from "redux-saga/effects";
import { setReports, setReport, fetchReportsStart } from "./reports.actions";
import {
  handleAddReport,
  handleFetchReports,
  handleFetchReport,
  handleDeleteReport,
} from "./reports.helpers";
import reportsTypes from "./reports.types";

export function* addReport({ payload }) {
  try {
    const timestamp = new Date();
    yield handleAddReport({
      ...payload,
      createdDate: timestamp,
    });
    yield put(fetchReportsStart());
  } catch (err) {}
}

export function* onAddReportStart() {
  yield takeLatest(reportsTypes.ADD_NEW_REPORT_START, addReport);
}

export function* fetchReports({ payload }) {
  try {
    const reports = yield handleFetchReports(payload);
    yield put(setReports(reports));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchReportsStart() {
  yield takeLatest(reportsTypes.FETCH_REPORTS_START, fetchReports);
}

export function* fetchReport({ payload }) {
  try {
    const report = yield handleFetchReport(payload);
    yield put(setReport(report));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchReportStart() {
  yield takeLatest(reportsTypes.FETCH_REPORT_START, fetchReport);
}

export function* onDeleteReportStart() {
  yield takeLatest(reportsTypes.DELETE_REPORT_START, deleteReport);
}

export function* deleteReport({ payload }) {
  try {
    yield handleDeleteReport(payload);
    yield put(fetchReportsStart());
  } catch (err) {
    // console.log(err);
  }
}

export default function* reportsSagas() {
  yield all([
    call(onAddReportStart),
    call(onFetchReportsStart),
    call(onDeleteReportStart),
    call(onFetchReportStart),
  ]);
}
