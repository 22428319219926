import businessOrdersTypes from "./businessOrders.types";
import businessOrderTypes from "./businessOrders.types";

const INITIAL_STATE = {
  businessOrders: [],
  businessOrder: {},
};

const businessOrdersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case businessOrderTypes.SET_BUSINESS_ORDERS:
      return {
        ...state,
        businessOrders: action.payload,
      };
    case businessOrdersTypes.SET_BUSINESS_ORDER:
      return {
        ...state,
        businessOrder: action.payload,
      };
    case businessOrdersTypes.UPDATE_BUSINESS_ORDER_SUCCESS:
      return {
        ...state,
        businessOrders: state.businessOrders.map((businessOrder) =>
          businessOrder.documentID === action.payload.documentID
            ? action.payload
            : businessOrder
        ),
        businessOrder:
          state.businessOrder.documentID === action.payload.documentID
            ? action.payload
            : state.businessOrder,
      };

    default:
      return state;
  }
};

export default businessOrdersReducer;
