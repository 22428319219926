import businessBookingsTypes from "./businessBookings.types";
import businessBookingTypes from "./businessBookings.types";

const INITIAL_STATE = {
  businessBookings: [],
  businessBooking: {},
};

const businessBookingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case businessBookingTypes.SET_BUSINESS_BOOKINGS:
      return {
        ...state,
        businessBookings: action.payload,
      };
    case businessBookingsTypes.SET_BUSINESS_BOOKING:
      return {
        ...state,
        businessBooking: action.payload,
      };
    case businessBookingsTypes.UPDATE_BUSINESS_BOOKING_SUCCESS:
      return {
        ...state,
        businessBookings: state.businessBookings.map((businessBooking) =>
          businessBooking.documentID === action.payload.documentID
            ? action.payload
            : businessBooking
        ),
        businessBooking:
          state.businessBooking.documentID === action.payload.documentID
            ? action.payload
            : state.businessBooking,
      };

    default:
      return state;
  }
};

export default businessBookingsReducer;
