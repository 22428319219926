const businessFeedbacksTypes = {
  ADD_NEW_BUSINESS_FEEDBACK_START: "ADD_NEW_BUSINESS_FEEDBACK_START",
  FETCH_BUSINESS_FEEDBACKS_START: "FETCH_BUSINESS_FEEDBACKS_START",
  SET_BUSINESS_FEEDBACKS: "SET_BUSINESS_FEEDBACKS",
  DELETE_BUSINESS_FEEDBACK_START: "DELETE_BUSINESS_FEEDBACK_START",
  FETCH_BUSINESS_FEEDBACK_START: "FETCH_BUSINESS_FEEDBACK_START",
  SET_BUSINESS_FEEDBACK: "SET_ADVICE",
};

export default businessFeedbacksTypes;
