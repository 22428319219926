import businessFeedbacksTypes from "./businessFeedbacks.types";
import businessFeedbackTypes from "./businessFeedbacks.types";

const INITIAL_STATE = {
  businessFeedbacks: [],
  businessFeedback: {},
};

const businessFeedbacksReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case businessFeedbackTypes.SET_BUSINESS_FEEDBACKS:
      return {
        ...state,
        businessFeedbacks: action.payload,
      };
    case businessFeedbacksTypes.SET_BUSINESS_FEEDBACK:
      return {
        ...state,
        businessFeedback: action.payload,
      };
    default:
      return state;
  }
};

export default businessFeedbacksReducer;
