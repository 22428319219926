import businessCartTypes from "./businessCart.types";
import {
  handleAddToBusinessCart,
  handleReduceBusinessCartItem,
} from "./businessCart.utils";

const INITIAL_STATE = {
  // businessCartItems: [],
  businessCarts: {},
};

const businessCartReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case businessCartTypes.ADD_TO_BUSINESS_CART: {
      const { businessID, nextBusinessCartItem } = action.payload;
      const businessCart = state.businessCarts[businessID] || {
        items: [],
        total: 0,
      };
      const prevBusinessCartItems = businessCart.items || []; // Ensure this is always an array

      return {
        ...state,
        businessCarts: {
          ...state.businessCarts,
          [businessID]: {
            ...businessCart,
            items: handleAddToBusinessCart({
              prevBusinessCartItems,
              nextBusinessCartItem,
            }),
          },
        },
      };
    }

    case businessCartTypes.REDUCE_BUSINESS_CART_ITEM: {
      const { businessID, businessCartItemToReduce } = action.payload;
      const businessCart = state.businessCarts[businessID] || {
        items: [],
        total: 0,
      };
      const updatedItems = handleReduceBusinessCartItem({
        prevBusinessCartItems: businessCart.items,
        businessCartItemToReduce,
      });

      return {
        ...state,
        businessCarts: {
          ...state.businessCarts,
          [businessID]: {
            ...businessCart,
            items: updatedItems,
          },
        },
      };
    }

    case businessCartTypes.REMOVE_BUSINESS_CART_ITEM: {
      const { businessID, businessCartItemToRemove } = action.payload;
      const businessCart = state.businessCarts[businessID] || {
        items: [],
        total: 0,
      };

      const updatedItems = businessCart.items.filter(
        (item) => item.recipeName !== businessCartItemToRemove.recipeName
      );

      return {
        ...state,
        businessCarts: {
          ...state.businessCarts,
          [businessID]: {
            ...businessCart,
            items: updatedItems,
          },
        },
      };
    }

    case businessCartTypes.CLEAR_BUSINESS_CART: {
      const { businessID } = action.payload;

      const updatedCarts = { ...state.businessCarts };
      delete updatedCarts[businessID];

      return {
        ...state,
        businessCarts: updatedCarts,
      };
    }
    default:
      return state;
  }
};

export default businessCartReducer;
