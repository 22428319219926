import { auth } from "../../firebase/utils";
import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  setBusinessFeedbacks,
  setBusinessFeedback,
  fetchBusinessFeedbacksStart,
} from "./businessFeedbacks.actions";
import {
  handleAddBusinessFeedback,
  handleFetchBusinessFeedbacks,
  handleFetchBusinessFeedback,
  handleDeleteBusinessFeedback,
} from "./businessFeedbacks.helpers";
import businessFeedbacksTypes from "./businessFeedbacks.types";

export function* addBusinessFeedback({ payload }) {
  try {
    const timestamp = new Date();
    yield handleAddBusinessFeedback({
      ...payload,
      userID: auth.currentUser.uid,
      userEmail: auth.currentUser.email,
      createdDate: timestamp,
    });
    yield put(fetchBusinessFeedbacksStart());
  } catch (err) {}
}

export function* onAddBusinessFeedbackStart() {
  yield takeLatest(
    businessFeedbacksTypes.ADD_NEW_BUSINESS_FEEDBACK_START,
    addBusinessFeedback
  );
}

export function* fetchBusinessFeedbacks({ payload }) {
  try {
    const businessFeedbacks = yield handleFetchBusinessFeedbacks(payload);
    yield put(setBusinessFeedbacks(businessFeedbacks));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchBusinessFeedbacksStart() {
  yield takeLatest(
    businessFeedbacksTypes.FETCH_BUSINESS_FEEDBACKS_START,
    fetchBusinessFeedbacks
  );
}

export function* fetchBusinessFeedback({ payload }) {
  try {
    const businessFeedback = yield handleFetchBusinessFeedback(payload);
    yield put(setBusinessFeedback(businessFeedback));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchBusinessFeedbackStart() {
  yield takeLatest(
    businessFeedbacksTypes.FETCH_BUSINESS_FEEDBACK_START,
    fetchBusinessFeedback
  );
}

export function* onDeleteBusinessFeedbackStart() {
  yield takeLatest(
    businessFeedbacksTypes.DELETE_BUSINESS_FEEDBACK_START,
    deleteBusinessFeedback
  );
}

export function* deleteBusinessFeedback({ payload }) {
  try {
    yield handleDeleteBusinessFeedback(payload);
    yield put(fetchBusinessFeedbacksStart());
  } catch (err) {
    // console.log(err);
  }
}

export default function* businessFeedbacksSagas() {
  yield all([
    call(onAddBusinessFeedbackStart),
    call(onFetchBusinessFeedbacksStart),
    call(onDeleteBusinessFeedbackStart),
    call(onFetchBusinessFeedbackStart),
  ]);
}
