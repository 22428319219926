const reportsTypes = {
  ADD_NEW_REPORT_START: "ADD_NEW_REPORT_START",
  FETCH_REPORTS_START: "FETCH_REPORTS_START",
  SET_REPORTS: "SET_REPORTS",
  DELETE_REPORT_START: "DELETE_REPORT_START",
  FETCH_REPORT_START: "FETCH_REPORT_START",
  SET_REPORT: "SET_REPORT",
};

export default reportsTypes;
