import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  setBusinessBookings,
  setBusinessBooking,
  fetchBusinessBookingsStart,
  updateBusinessBookingSuccess,
} from "./businessBookings.actions";
import {
  handleAddBusinessBooking,
  handleFetchBusinessBookings,
  handleFetchBusinessBooking,
  handleDeleteBusinessBooking,
  handleUpdateBusinessBooking,
} from "./businessBookings.helpers";
import businessBookingsTypes from "./businessBookings.types";

export function* addBusinessBooking({ payload }) {
  try {
    const { businessBookingID, ...businessBookingData } = payload;

    const timestamp = new Date();
    yield handleAddBusinessBooking(
      {
        ...businessBookingData,
        createdDate: timestamp,
      },
      businessBookingID
    );

    yield put(fetchBusinessBookingsStart());
  } catch (err) {
    console.error("Error adding business booking:", err);
  }
}

export function* onAddBusinessBookingStart() {
  yield takeLatest(
    businessBookingsTypes.ADD_NEW_BUSINESS_BOOKING_START,
    addBusinessBooking
  );
}

export function* fetchBusinessBookings({ payload }) {
  try {
    const businessBookings = yield handleFetchBusinessBookings(payload);
    yield put(setBusinessBookings(businessBookings));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchBusinessBookingsStart() {
  yield takeLatest(
    businessBookingsTypes.FETCH_BUSINESS_BOOKINGS_START,
    fetchBusinessBookings
  );
}

export function* fetchBusinessBooking({ payload }) {
  try {
    const businessBooking = yield handleFetchBusinessBooking(payload);
    yield put(setBusinessBooking(businessBooking));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchBusinessBookingStart() {
  yield takeLatest(
    businessBookingsTypes.FETCH_BUSINESS_BOOKING_START,
    fetchBusinessBooking
  );
}

export function* onDeleteBusinessBookingStart() {
  yield takeLatest(
    businessBookingsTypes.DELETE_BUSINESS_BOOKING_START,
    deleteBusinessBooking
  );
}

export function* deleteBusinessBooking({ payload }) {
  try {
    yield handleDeleteBusinessBooking(payload);
    yield put(fetchBusinessBookingsStart());
  } catch (err) {
    // console.log(err);
  }
}

export function* updateBusinessBooking({ payload }) {
  try {
    const updatedBusinessBooking = yield handleUpdateBusinessBooking(payload);
    yield put(updateBusinessBookingSuccess(updatedBusinessBooking));
  } catch (err) {
    console.error("Update business saga error:", err);
  }
}

export function* onUpdateBusinessBookingStart() {
  yield takeLatest(
    businessBookingsTypes.UPDATE_BUSINESS_BOOKING_START,
    updateBusinessBooking
  );
}

export default function* businessBookingsSagas() {
  yield all([
    call(onAddBusinessBookingStart),
    call(onFetchBusinessBookingsStart),
    call(onDeleteBusinessBookingStart),
    call(onFetchBusinessBookingStart),
    call(onUpdateBusinessBookingStart),
  ]);
}
