import reportsTypes from "./reports.types";

export const addReportStart = (reportData) => ({
  type: reportsTypes.ADD_NEW_REPORT_START,
  payload: reportData,
});

export const fetchReportsStart = (filters = {}) => ({
  type: reportsTypes.FETCH_REPORTS_START,
  payload: filters,
});

export const setReports = (reports) => ({
  type: reportsTypes.SET_REPORTS,
  payload: reports,
});

export const deleteReportStart = (reportID) => ({
  type: reportsTypes.DELETE_REPORT_START,
  payload: reportID,
});

export const fetchReportStart = (reportID) => ({
  type: reportsTypes.FETCH_REPORT_START,
  payload: reportID,
});

export const setReport = (report) => ({
  type: reportsTypes.SET_REPORT,
  payload: report,
});
