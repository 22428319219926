import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/messaging";
import { sendEmailVerification } from "../redux/user/user.sagas";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const firestore = firebase.firestore();
const storage = firebase.storage();

export { firestore, auth, storage };

export const GoogleProvider = new firebase.auth.GoogleAuthProvider();
GoogleProvider.setCustomParameters({ prompt: "select_account" });

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((userAuth) => {
      unsubscribe();
      resolve(userAuth);
    }, reject);
  });
};

// DB PERSISTENCE
const firestoreSettings = {
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
  synchronizeTabs: true,
};
firestore.settings({
  ...firestoreSettings,
  merge: true,
});
try {
  firestore.FirestoreSettings.cache(firestoreSettings);
} catch (err) {
  if (err.code === "failed-precondition") {
    // console.log("MUltiple tabs open")
  } else if (err.code === "unimplemented") {
    // console.log("Does not support persistence")
  }
}

//GENERATE USERNAME IF IT DOESNT SET
function generateUsername(displayName) {
  const firstName = displayName.split(" ")[0].toLowerCase();
  const randomChars = Math.random().toString(36).substring(2, 7);
  return `${firstName}${randomChars}`;
}

//CREATE USER PROFILE
// export const handleUserProfile = async ({
//   userAuth,
//   additionalData,
//   navigate,
// }) => {
//   const { uid } = userAuth;
//   const userRef = firestore.doc(`users/${uid}`);
//   const snapshot = await userRef.get();

//   if (!snapshot.exists) {
//     //PESONAL DETAILS
//     const { displayName, email, photoURL, username } = userAuth;
//     let newUsername;
//     if (userAuth.providerData[0].providerId === "google.com") {
//       newUsername = generateUsername(displayName);
//     } else {
//       newUsername = username;
//     }
//     const gender = null;
//     const birthDate = null;
//     //CONTACT
//     const address = null;
//     const biography = "";
//     const phoneNumber = null;
//     const website = null;
//     const instagram = null;
//     const facebook = null;
//     const tiktok = null;
//     const pinterest = null;
//     const youtube = null;
//     //PREFERENCES
//     const familyMembers = "1";
//     const cookForPets = null;
//     const cookForKids = null;
//     const dietaryRestrictions = null;
//     const dietaryPreferences = null;
//     const favoriteIngredients = null;
//     //STATUS
//     const isOnline = false;
//     const isBlocked = false;
//     const isVerified = false;
//     //USER ROLES
//     const userRoles = ["user"];
//     //PRIVACY
//     const privacy = false;
//     //CREATED DATE
//     const timestamp = new Date();
//     try {
//       await userRef.set({
//         //PESONAL DETAILS
//         displayName,
//         username: newUsername,
//         email,
//         photoURL,
//         gender,
//         birthDate,
//         //CONTACT
//         address,
//         phoneNumber,
//         website,
//         biography,
//         instagram,
//         facebook,
//         tiktok,
//         pinterest,
//         youtube,
//         //PREFERENCES
//         familyMembers,
//         cookForPets,
//         cookForKids,
//         dietaryRestrictions,
//         dietaryPreferences,
//         favoriteIngredients,
//         //STATUS
//         isOnline,
//         isVerified,
//         isBlocked,
//         //USER ROLES
//         userRoles,
//         //PRIVACY
//         privacy,
//         createdDate: timestamp,
//         ...additionalData,
//       });
//       navigate("/complete-profile");
//       console.log(email);
//       yield put(emailVerificationStart({ email }));
//     } catch (err) {
//       console.log(err);
//     }
//   }

//   return userRef;
// };

//CREATE USER PROFILE
export function* handleUserProfile({ userAuth, additionalData, navigate }) {
  const { uid } = userAuth;
  const userRef = firestore.doc(`users/${uid}`);
  const snapshot = yield userRef.get();

  if (!snapshot.exists) {
    //PESONAL DETAILS
    const { displayName, email, photoURL, username } = userAuth;
    let newUsername;
    if (userAuth.providerData[0].providerId === "google.com") {
      newUsername = generateUsername(displayName);
    } else {
      newUsername = username;
    }
    const gender = null;
    const birthDate = null;
    //CONTACT
    const address = null;
    const biography = "";
    const phoneNumber = null;
    const website = null;
    const instagram = null;
    const facebook = null;
    const tiktok = null;
    const pinterest = null;
    const youtube = null;
    //PREFERENCES
    const familyMembers = "1";
    const cookForPets = null;
    const cookForKids = null;
    const dietaryRestrictions = null;
    const dietaryPreferences = null;
    const favoriteIngredients = null;
    //STATUS
    const isOnline = false;
    const isBlocked = false;
    const isVerified = false;
    //USER ROLES
    const userRoles = ["user"];
    //PRIVACY
    const privacy = false;
    //CREATED DATE
    const timestamp = new Date();
    try {
      yield userRef.set({
        //PESONAL DETAILS
        displayName,
        username: newUsername,
        email,
        photoURL,
        gender,
        birthDate,
        //CONTACT
        address,
        phoneNumber,
        website,
        biography,
        instagram,
        facebook,
        tiktok,
        pinterest,
        youtube,
        //PREFERENCES
        familyMembers,
        cookForPets,
        cookForKids,
        dietaryRestrictions,
        dietaryPreferences,
        favoriteIngredients,
        //STATUS
        isOnline,
        isVerified,
        isBlocked,
        //USER ROLES
        userRoles,
        //PRIVACY
        privacy,
        createdDate: timestamp,
        ...additionalData,
      });
      navigate("/complete-profile");
      sendEmailVerification(email);
    } catch (err) {
      console.log(err);
    }
  }

  return userRef;
}
