import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
//HOC
import { checkUserIsAdmin } from "../../hooks/userRoles";

//COMPONENTS
import { ModalBottom } from "../genericComponents/Modals";
import { ListQuantity } from "../Counters";
import { ButtonBack } from "../formElements/Buttons";
import { NotificationsCounter } from "../Counters";

//ASSETS
import {
  HiOutlineBell,
  HiPlus,
  HiOutlineCalendar,
  HiOutlineNewspaper,
  HiOutlineBriefcase,
} from "react-icons/hi";
import { TbCarrot, TbToolsKitchen3 } from "react-icons/tb";
import { RiFileList3Line, RiRobot2Line } from "react-icons/ri";
import { CgBowl } from "react-icons/cg";
import "./Index.scss";
import { createSelector } from "reselect";

const businessesSelector = (state) => state.businessesData.businesses;
const businessSelector = (state) => state.businessesData.business;
const currentUserSelector = (state) => state.user.currentUser;

const mapState = createSelector(
  [businessesSelector, businessSelector, currentUserSelector],
  (businesses, business, currentUser) => ({
    businesses,
    business,
    currentUser,
  })
);

export function Nav() {
  const { currentUser } = useSelector(mapState);
  const { t } = useTranslation();
  const isAdmin = checkUserIsAdmin(currentUser);
  const location = useLocation();
  const [selected, setSelected] = useState("");
  useEffect(() => {
    switch (location.pathname) {
      case "/food-gpt":
        setSelected("left");
        break;
      case "/explore":
        setSelected("centerLeft");
        break;
      case "/menu-planner":
        setSelected("centerRight");
        break;
      case "/shopping-list":
        setSelected("right");
        break;
      default:
        setSelected("");
    }
  }, [location.pathname]);

  //MODAL
  const [hideModalBottom, setHideModalBottom] = useState(true);
  const toggleModalBottom = () => setHideModalBottom(!hideModalBottom);
  const configModal = {
    hideModalBottom,
    toggleModalBottom,
  };

  //HIDE NAV WHEN SCROLLED
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    setVisible(prevScrollPos > currentScrollPos || currentScrollPos === 0);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  useEffect(() => {
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navTopStyle = {
    transform: visible ? "translate(-50%, 0px)" : "translate(-50%, -200%)",
    transition: "0.35s linear",
  };
  const navBottomStyle = {
    transform: visible ? "translate(-50%, 0px)" : "translate(-50%, 170%)",
  };

  if (!currentUser) return <NavPage />;

  return (
    <>
      <nav className="navTop" id="nav" style={navTopStyle}>
        <NavLink activeclassname="active" to="/dashboard">
          <button aria-label="logoBtn" className="logoBtn">
            <img src="/assets/logo.svg" alt="listeat" className="logo" />
          </button>
        </NavLink>
        <div className="navButtons">
          <button
            className="navTopBtn"
            aria-label="addBtn"
            onClick={() => toggleModalBottom()}
            id="addButton"
          >
            <HiPlus />
          </button>
          <NavLink activeclassname="active" to="/notifications">
            <button
              aria-label="notificationsBtn"
              className="navTopBtn"
              id="notificationsButton"
            >
              <NotificationsCounter />
              <HiOutlineBell />
            </button>
          </NavLink>
          <NavLink activeclassname="active" to="/account">
            <button
              aria-label="accountBtn"
              type="button"
              className="navTopBtn"
              id="accountButton"
            >
              <img src={currentUser.photoURL} alt="Listeat" />
            </button>
          </NavLink>
        </div>
      </nav>

      <nav
        className={`navBottom ${
          currentUser.userRoles.includes("business") ? "five" : ""
        }`}
        style={navBottomStyle}
      >
        <span className={`selected ${selected}`} />
        <NavLink activeclassname="active" to="/food-gpt">
          <button aria-label="foodGpt button" id="foodGpt">
            <RiRobot2Line />
          </button>
        </NavLink>
        <NavLink activeclassname="active" to="/explore">
          <button aria-label="exploreBtn" id="exploreButton">
            <TbToolsKitchen3 />
          </button>
        </NavLink>
        <NavLink activeclassname="active" to="/menu-planner">
          <button aria-label="plannerBtn" id="menuPlannerButton">
            <HiOutlineCalendar />
          </button>
        </NavLink>
        <NavLink activeclassname="active" to="/shopping-list">
          <button aria-label="listsBtn" id="shoppingListButton">
            <RiFileList3Line />
          </button>
          {!currentUser ? null : <ListQuantity />}
        </NavLink>
        {currentUser.userRoles.includes("business") && (
          <NavLink activeclassname="active" to="/professional-dashboard">
            <button aria-label="listsBtn" id="professionalDashboardButton">
              <HiOutlineBriefcase />
            </button>
          </NavLink>
        )}
      </nav>
      <ModalBottom id={currentUser.id} {...configModal}>
        <h3>{t("Add")}</h3>
        <div className="flexShare">
          <NavLink to="/add-recipe">
            <button aria-label="addRecipeBtn" className="flexShareBtn">
              <span>
                <CgBowl />
              </span>
              <small>{t("Recipe")}</small>
            </button>
          </NavLink>
          <NavLink to="/add-menu">
            <button aria-label="addMenuBtn" className="flexShareBtn">
              <span>
                <HiOutlineCalendar />
              </span>
              <small>{t("Menu")}</small>
            </button>
          </NavLink>
          <NavLink to="/add-ingredient" activeclassname="active">
            <button aria-label="addIngredientBtn" className="flexShareBtn">
              <span>
                <TbCarrot />
              </span>
              <small>{t("Ingredient")}</small>
            </button>
          </NavLink>
          {isAdmin && (
            <NavLink to="/add-article" activeclassname="active">
              <button aria-label="addBlogBtn" className="flexShareBtn">
                <span>
                  <HiOutlineNewspaper />
                </span>
                <small>{t("Blog post")}</small>
              </button>
            </NavLink>
          )}
        </div>
      </ModalBottom>
    </>
  );
}
export function NavPage(props) {
  //HIDE NAV WHEN SCROLLED
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setVisible(prevScrollPos > currentScrollPos || currentScrollPos === 0);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  useEffect(() => {
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navStyle = {
    transform: visible ? "translateY(0)" : "translateY(-200%)",
  };

  return (
    <nav
      className={`navSecondary ${props.transparent ? "transparent" : ""} ${
        props.withImage ? "image" : ""
      } ${props.fullWidth ? "fullWidth" : ""}`}
      style={navStyle}
    >
      {!props.hideBackButton && (
        <>
          {!props.homeBackButton ? (
            <ButtonBack />
          ) : (
            <ButtonBack toHomepage={true} />
          )}
        </>
      )}
      <h1>{props.title}</h1>
      {props.withLogo ? (
        <img src={props.logo} alt="logo" className="navBusinessLogo" />
      ) : null}
      {props.withImage ? <img src={props.image} alt="Listeat" /> : null}
    </nav>
  );
}
// export function NavBusiness({ business }) {
//   const { currentUser } = useSelector(mapState);
//   const { t } = useTranslation();
//   const { businessID } = useParams();
//   const businessCartItems = useSelector((state) =>
//     selectBusinessCartItemsForBusiness(state, businessID)
//   );
//   //HIDE NAV WHEN SCROLLED
//   const [prevScrollPos, setPrevScrollPos] = useState(0);
//   const [visible, setVisible] = useState(true);

//   const handleScroll = () => {
//     const currentScrollPos = window.pageYOffset;
//     setVisible(prevScrollPos > currentScrollPos || currentScrollPos === 0);
//     setPrevScrollPos(currentScrollPos);
//   };

//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll);

//     return () => window.removeEventListener("scroll", handleScroll);
//   }, [prevScrollPos]);

//   useEffect(() => {
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   const navStyle = {
//     transform: visible ? "translateY(0)" : "translateY(-200%)",
//   };

//   //MODAL

//   const [hideModalBottom, setHideModalBottom] = useState(true);
//   const toggleModalBottom = () => setHideModalBottom(!hideModalBottom);
//   const [hideModalBottom2, setHideModalBottom2] = useState(true);
//   const toggleModalBottom2 = () => setHideModalBottom2(!hideModalBottom2);
//   const configModal = {
//     hideModalBottom,
//     toggleModalBottom,
//     hideModalBottom2,
//     toggleModalBottom2,
//   };

//   return (
//     <>
//       <nav className="navBottom business" style={navStyle}>
//         <NavLink activeclassname="active" to={`/business/${businessID}`}>
//           <button>
//             <BiHomeAlt />
//             <span>{t("Home")}</span>
//           </button>
//         </NavLink>
//         <NavLink activeclassname="active" to={`/business-menu/${businessID}`}>
//           <button>
//             <GiForkKnifeSpoon />
//             <span>{t("Menu")}</span>
//           </button>
//         </NavLink>
//         <button onClick={() => toggleModalBottom2()}>
//           <HiOutlineClock />
//           <span>{t("Booking")}</span>
//         </button>
//         <button onClick={() => toggleModalBottom()}>
//           <HiOutlineShoppingBag />
//           <span>{t("Cart")}</span>
//           <span className="listQuantityButton right">
//             <span>{businessCartItems ? businessCartItems.length : 0}</span>
//           </span>
//         </button>
//       </nav>
//       <ModalBottom {...configModal}>
//         <h3 className="flexHeader">
//           Cart<button>{t("Clear")}</button>
//         </h3>
//         <CheckoutList
//           toggleModalBottom={() => toggleModalBottom()}
//           business={business}
//         />
//       </ModalBottom>

//       <ModalBottom2 {...configModal}>
//         <BusinessBooking
//           currentUser={currentUser}
//           phoneNumber={business.contactPhone}
//           toggleModalBottom2={() => toggleModalBottom2()}
//         />
//       </ModalBottom2>
//     </>
//   );
// }
